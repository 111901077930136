import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found"/>
    </Layout>
);

export default NotFoundPage;
